<template>
  <Fragment>
    <main class="maincontent">
      <div v-if="profile.isPremiumMember" class="mainh1 mainh1_sticky">
        <div class="wrapper">
          <div class="mainh1_flex mainh1_history">
            <h1>Workouts Calendar</h1>
            <MonthMenu :month="month"></MonthMenu>
          </div>
        </div>
      </div>
      <section v-if="profile.isPremiumMember" class="history">
        <div class="wrapper">
          <div class="history_alert" v-if="showDemoMessage">
            This is your workout schedule for your current plan. Need to switch
            your workout days and rest days? Move the circles below to create a
            custom schedule.<span @click="showDemoMessage = false" class="close"><i
                class="atuicons atuicons-close"></i></span>
          </div>
          <div class="history_showinstructions">
            <button @click="showDemo = true" class="button button_red">
              Show instructions
            </button>
          </div>
          <div class="history_editmode">
            <div class="history_editmode_title title_small">Edit Schedule</div>
            <label class="editmode"><input type="checkbox" v-model="canEdit" /><span></span></label>
          </div>
          <MonthHistoryList :month="month" :canEdit="canEdit" :lastWorkoutLog="lastWorkoutLog"></MonthHistoryList>
        </div>
      </section>
      <section v-if="!profile.isPremiumMember">
        <div class="wrapper">
          <BuyAlert></BuyAlert>
        </div>
      </section>
      <div class="cannotchange">
        <p>Only rest days can be moved.</p>
      </div>
    </main>
    <SwitchDemo v-if="showDemo" @closeDemo="showDemo = false"></SwitchDemo>
  </Fragment>
</template>

<script>
import custom_js from "./custom";
import { MonthMenu, MonthHistoryList, SwitchDemo } from "./components";
import BuyAlert from "@/components/BuyAlert";
import { Fragment } from "vue-fragment";
import { getLastWorkoutLog } from '@/api/workoutlog'
import { waitForElement } from "@/utils/tool";


export default {
  name: "Schedule",
  data() {
    return {
      showDemo: false,
      showDemoMessage: true,
      canEdit: false,
      lastWorkoutLog: { month: 1, day: 1 },
    };
  },
  components: { MonthMenu, MonthHistoryList, BuyAlert, SwitchDemo, Fragment },
  methods: {
    customjs() {
      custom_js();
    },
    async scrollToCurrentMonth() {
      let a = await waitForElement('a.today');
      if(!a){
        return;
      }
      const tab = await waitForElement(`a[href='#Month${this.lastWorkoutLog.month}']`);

      for (let i = 0; i < 6; i++) {
        if (a.previousElementSibling) {
          a = a.previousElementSibling;
        } else {
          if (tab) {
            tab.click();
            return;
          }
        }
      }
      a.scrollIntoView({
        behavior: 'smooth'
      });
    },
  },
  created: function () {
    getLastWorkoutLog().then(({ data }) => {
      this.lastWorkoutLog.day = parseInt(data.day);
      this.lastWorkoutLog.month = parseInt(data.month);
    });
  },
  mounted: function () {
    this.customjs();
    this.scrollToCurrentMonth();

  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
    month() {
      return parseInt(this.profile.month);
    },
  },
};
</script>

<style scoped>
@import "~@/assets/css/history.css";
</style>
