import $ from "jquery";

export default function () {
    $(".maincontent").on("click", ".history_filtermonths li", function (e) {
        e.preventDefault();
        if ($(this).hasClass("available")) {
            let scrollTo = $(this).children("a").attr("href");
            let scrollToFixTop = $(".mainh1_sticky").outerHeight() + 10;
            $('html, body').animate({
                scrollTop: $(scrollTo).offset().top - scrollToFixTop
            }, 1000);

        }
    })
}