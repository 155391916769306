<template>
  <div
    :class="{ history_month: true, notavailable, short }"
    :id="`Month${month}`"
  >
    <div class="history_month_progress"></div>
    <div class="history_month_title">{{ `Month ${month}` }}</div>
    <draggable
      :list="workouts"
      :disabled="!enable"
      class="history_month_days"
      :move="checkMove"
    >
      <a
        v-for="(workout, index) in workouts"
        :key="index"
        :class="{
          history_month_day: true,
          rest: isRest(workout),
          today: isToday(index + 1),
          current: isRedirectable(workout, index + 1),
          empty: month > profile.month,
        }"
        @click.prevent="onClick(workout, index + 1)"
        >{{ isRest(workout) ? "Rest" : workout.number }}</a
      >
    </draggable>

    <div v-if="enable" :class="`savechanges month${month}`"  @click="saveSchedule">
      <SaveButton :isLoading="isLoading" :text="`Save Changes For Month=${month}`"></SaveButton>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import SaveButton from "@/components/SaveButton";
import { saveSchedule } from "@/api/schedule";
import NProgress from "nprogress";
import swal from "sweetalert";

export default {
  name: "MonthHistory",
  data() {
    return {
      isLoading: false,
    };
  },
  props: {
    notavailable: Boolean,
    short: Boolean,
    month: Number,
    workouts: Array,
    canEdit: Boolean,
    lastWorkoutLog: Object
  },
  components: {
    draggable,
    SaveButton,
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
    enable() {
    return this.canEdit;
    },
  },
  methods: {
    checkMove(event) {
      if (event.draggedContext.element.name.toLowerCase() == "rest") {
        this.showButton();
        this.hideAlert();
        return true;
      }
      this.showAlert();
      this.hideButton();
      setTimeout(() => {
        this.showButton();
        this.hideAlert();
      }, 2000);
      return false;
    },
    getAlert() {
      return document.getElementsByClassName("cannotchange")[0];
    },
    getButton() {
      return document.getElementsByClassName(`savechanges month${this.month}`)[0];
    },
    showButton() {
      this.getButton().style.display = "block";
    },
    hideButton() {
      this.getButton().style.display = "none";
    },
    showAlert() {
      this.getAlert().style.display = "block";
    },
    hideAlert() {
      this.getAlert().style.display = "none";
    },
    saveSchedule() {
      NProgress.start();
      this.isLoading = true;
      saveSchedule({ workouts: this.workouts, month: this.month })
        .then((response) => {
          this.hideButton();
          swal(
            "Saved!",
            response.message ||
              "Your custom workouts have been saved successfully",
            "success"
          );
        })
        .catch(() => {
          console.log("error while saving schedule");
        })
        .finally(() => {
          this.isLoading = false;
          NProgress.done();
        });
    },
    onClick(workout, day) {
      if (!this.isRedirectable(workout, day)) {
        console.log("no redirect");
        return;
      }
      this.$router.push(`/workout/index?day=${day}&month=${this.month}`);
    },
    isRedirectable(workout, day) {
      if (this.isRest(workout)) {
        return false;
      }

      if (this.profile.month >= this.month) {
        return true;
      }

      let currentTimeString =
        this.getWithPad(this.profile.month) +
        "" +
        this.getWithPad(this.profile.day);
      let circleTimeString =
        this.getWithPad(this.month) + "" + this.getWithPad(day);

      return parseInt(circleTimeString) <= parseInt(currentTimeString);
    },
    getWithPad(number) {
      return String(number).padStart(4, "0");
    },
    isRest(workout) {
      return workout.name.toLowerCase() == "rest";
    },
    isToday(day) {
      let m = this.lastWorkoutLog.month;
      let d = this.lastWorkoutLog.day;
      if (28 === d) {
        d = 1;
        m++;
      } else {
        d++;
      }
      return this.month === m && day === d;
    },
  },
};
</script>

<style scoped>
a.rest {
  cursor: not-allowed;
}

div.notavailable a[class="!current"] {
  cursor: not-allowed;
}

div.notavailable a.empty {
  cursor: not-allowed;
}
</style>
