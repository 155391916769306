<template>
  <div class="history_months">
    <MonthHistory
      v-for="item in items"
      :key="`${item.id}-${item.month}`"
      :notavailable="isNotAvailable()"
      :month="item.month"
      :workouts="item.workouts"
      :canEdit="canEdit"
      :lastWorkoutLog="lastWorkoutLog"
    ></MonthHistory>
  </div>
</template>


<script>
import NProgress from "nprogress";
import { fetchSchedule } from "@/api/schedule";
import MonthHistory from "./MonthHistory";

export default {
  name: "MonthHistoryList",
  components: { MonthHistory },
  props: { lastWorkoutLog:Object, month: Number, canEdit:Boolean },
  data() {
    return {
      items: []
    };
  },
  methods: {
    isNotAvailable() {
      return false;
    },
    fetchSchedule() {
      NProgress.start();
      fetchSchedule()
        .then(({ data }) => {
          this.items = data;
        })
        .catch(() => {
          console.log("error while fetch schedule");
        })
        .finally(() => {
          NProgress.done();
        });
    },
  },
  created() {
    this.fetchSchedule();
  },
};
</script>