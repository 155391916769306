<template>
  <div class="history_filtermonths">
    <div class="history_filtermonths_scroll">
      <ul>
        <li class="available" v-for="index in items" :key="index + 1">
          <a :href="`#Month${index + 1}`">Month {{ index + 1 }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "MonthMenu",
  props: { month: Number },
  mounted: function () {
    console.log("theitems", this.items);
    console.log("themonth", this.month);
  },
  computed: {
    items: function () {
      console.log("themonth", this.month);

      let items = [];
      for (let i = 0; i < this.month; i++) {
        items.push(i);
      }
      return items.reverse();
    },
  },
};
</script>