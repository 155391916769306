import request from '@/utils/request'

export function fetchSchedule() {
    return request({
        url: `/api/schedule/index`,
        method: 'get',
    })
}

export function saveSchedule(data) {
    return request({
        url: `/api/custom-workout/save`,
        method: 'post',
        data
    })
}

