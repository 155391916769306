<template>
  <div class="demochangedate">
    <div class="demochangedate-close" @click="closeDemo">
      <i class="atuicons atuicons-close"></i>
    </div>
    <div class="demochangedate_wrapper">
      <div class="demochangedate_text">
        Here is how you can customize your workout schedule:
      </div>
      <div class="history_month_days">
        <span class="history_month_day">1</span>
        <span class="history_month_day rest">Rest</span>
        <span class="history_month_day rest fromMove">3</span
        ><span class="history_month_day toMove"
          >Rest<span class="icon-touch"></span></span
        ><span class="history_month_day">5</span
        ><span class="history_month_day">6</span
        ><span class="history_month_day rest">Rest</span
        ><span class="history_month_day today">8</span
        ><span class="history_month_day">9</span
        ><span class="history_month_day">10</span
        ><span class="history_month_day current">11</span
        ><span class="history_month_day rest">Rest</span
        ><span class="history_month_day">13</span
        ><span class="history_month_day">14</span
        ><span class="history_month_day rest">Rest</span
        ><span class="history_month_day rest">Rest</span
        ><span class="history_month_day">17</span
        ><span class="history_month_day">18</span
        ><span class="history_month_day rest">Rest</span
        ><span class="history_month_day rest">Rest</span
        ><span class="history_month_day">21</span
        ><span class="history_month_day">22</span
        ><span class="history_month_day rest">Rest</span
        ><span class="history_month_day rest">Rest</span
        ><span class="history_month_day">25</span
        ><span class="history_month_day">26</span
        ><span class="history_month_day">27</span
        ><span class="history_month_day">28</span>
      </div>
      <div class="demochangedate_button">
        <button class="button button_red">Save Changes</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SwitchDemo",
  methods: {
    closeDemo() {
      this.$emit("closeDemo");
    },
  },
};
</script>
